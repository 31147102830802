<template>
  <div>
    <b-button v-b-modal.modal-b2 class="notify-hidden" id="notify-hidden"
      >ForgotPasswordModal</b-button
    >
    <b-modal
      id="modal-password"
      :hide-footer="true"
      centered
      ref="modal-password"
      @hide="hideModal"
    >
      <div
        class="card shadow-none border-0 pb-5 mb-3 card-body px-lg-5 py-lg-3 modal-from-tour"
      >
        <div class="px-2">
          <el-steps :active="active" finish-status="success" align-center>
            <el-step title="Enter login id"> </el-step>
            <el-step title="Verify OTP"> </el-step>
            <el-step title="Reset password"> </el-step>
          </el-steps>
          <div style="height: 400px" v-if="active == 0">
            <h3 class="p-5">{{ $t('Register_and_Login.forgot_password') }}</h3>
            <form
              role="form"
              class="px-5 pt-4 modal-form modal-forgot-password"
              @submit.prevent="submitFormLoginId"
            >
              <div>
                <b-form-group class="Email-group">
                  <b-input-group>
                    <b-form-input
                      :placeholder="$t('register.loginIdPlaceHolder')"
                      class="py-3 shadow-sm"
                      v-model="$v.form.loginId.$model"
                      :state="validateStateLoginId('loginId')"
                      aria-describedby="input-1-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-1-live-feedback"
                      >{{
                        $t('Register_and_Login.required_field4')
                      }}.</b-form-invalid-feedback
                    >
                  </b-input-group>
                </b-form-group>
              </div>
              <div style="height: 50px"></div>
              <div class="text-center">
                <b-button
                  type="submit"
                  variant="second"
                  class="my-4 btn-block btn-login"
                  v-if="!busy"
                  >{{ $t('Register_and_Login.retrieve') }}</b-button
                >
                <b-button
                  class="my-4 btn-block btn-login"
                  v-else
                  variant="warning"
                  disabled
                >
                  <b-spinner small type="grow"></b-spinner>
                  {{ $t('Register_and_Login.loading') }}...
                </b-button>
              </div>
            </form>
          </div>
          <div style="height: 400px" v-if="active == 1">
            <h3 class="p-5">{{ $t('Register_and_Login.forgot_password') }}</h3>
            <form
              role="form"
              class="px-5 pt-4 modal-form modal-forgot-password"
              @submit.prevent="submitFormOTP"
            >
              <div>
                <b-form-group class="otpCode-group">
                  <b-input-group>
                    <b-form-input
                      placeholder="Enter code OTP"
                      class="py-3 shadow-sm"
                      v-model="$v.form1.otpCode.$model"
                      :state="validateStateOtp('otpCode')"
                      aria-describedby="input-2-live-feedback"
                    ></b-form-input>
                    <!-- <b-input-group-text
                      slot="append"
                      class="otp-btn"
                      id="otp-countdown"
                      >60S</b-input-group-text
                    > -->
                    <b-form-invalid-feedback id="input-2-live-feedback"
                      >{{
                        $t('Register_and_Login.required_field6')
                      }}.</b-form-invalid-feedback
                    >
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="text-center">
                <b-button
                  type="submit"
                  variant="second"
                  v-if="!busy"
                  class="my-4 btn-block btn-login"
                  >{{ $t('Register_and_Login.retrieve') }}</b-button
                >
                <b-button
                  class="my-4 btn-block btn-login"
                  v-else
                  variant="warning"
                  disabled
                >
                  <b-spinner small type="grow"></b-spinner>
                  {{ $t('Register_and_Login.loading') }}...
                </b-button>
              </div>
            </form>
          </div>
          <div style="height: 400px" v-if="active == 2">
            <h3 class="p-5">{{ $t('Register_and_Login.forgot_password') }}</h3>
            <form
              role="form"
              class="px-5 pt-4 modal-form modal-forgot-password"
              @submit.prevent="submitFormResetPassword"
            >
              <div>
                <div>
                  <b-form-group class="password-group">
                    <b-input-group>
                      <b-form-input
                        id="password"
                        :placeholder="$t('register.passwordPlaceHolder')"
                        class="py-3 shadow-sm"
                        name="password"
                        v-validate="{
                          required: true,
                          numeric,
                          min: 6,
                          max: 16,
                        }"
                        v-model="form2.password"
                        :state="validateStateResetPassword('password')"
                        aria-describedby="input-3-live-feedback"
                        type="password"
                        ref="password"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-3-live-feedback">{{
                        $t('register.passwordGuide')
                      }}</b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group class="password-confirm-group">
                    <b-input-group>
                      <b-form-input
                        id="confirmPassword"
                        :placeholder="$t('register.confirmPasswordPlaceHolder')"
                        class="py-3 shadow-sm mb-3"
                        type="password"
                        name="confirmPassword"
                        v-validate="'required|confirmed:password'"
                        v-model="form2.confirmPassword"
                        :state="validateStateResetPassword('confirmPassword')"
                        aria-describedby="input-4-live-feedback"
                        data-vv-as="password"
                      ></b-form-input>

                      <b-form-invalid-feedback id="input-4-live-feedback"
                        >{{
                          $t('Register_and_Login.required_field_password')
                        }}.</b-form-invalid-feedback
                      >
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>
              <div class="text-center">
                <b-button
                  type="submit"
                  variant="second"
                  v-if="!busy"
                  class="my-4 btn-block btn-login"
                  >{{ $t('Register_and_Login.retrieve') }}</b-button
                >
                <b-button
                  class="my-4 btn-block btn-login"
                  v-else
                  variant="warning"
                  disabled
                >
                  <b-spinner small type="grow"></b-spinner>
                  {{ $t('Register_and_Login.loading') }}...
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHeadset,
  faQuestionCircle,
  faMedkit,
  faLifeRing,
} from '@fortawesome/free-solid-svg-icons';

import { validationMixin } from 'vuelidate';
import config from '@/app.config';
import {
  required,
  minLength,
  maxLength,
  numeric,
} from 'vuelidate/lib/validators';
import axios from 'axios';
const url = config.user_service_url;
library.add(faHeadset, faQuestionCircle, faMedkit, faLifeRing);

export default {
  mixins: [validationMixin],
  data() {
    return {
      otpSw: 'Y',
      otpMthd: 'E',
      active: 0,
      readonly: true,
      countdown: 180,
      lang: this.$language,
      form: {
        loginId: '',
      },
      form1: {
        otpCode: '',
      },
      form2: {
        password: '',
        confirmPassword: '',
      },
      busy: false,
    };
  },
  validations() {
    return {
      form: {
        loginId: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(20),
        },
      },
      form1: {
        otpCode: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(6),
          numeric,
        },
      },
    };
  },
  methods: {
    validateStateLoginId(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateOtp(otp) {
      const { $dirty, $error } = this.$v.form1[otp];
      return $dirty ? !$error : null;
    },
    validateStateResetPassword(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    showModal() {
      this.$refs['modal-password'].show();
    },
    hideModal() {
      this.resetFormResetPassowrd();
      this.active = 0;
      this.$refs['modal-password'].hide();
    },
    resetFormResetPassowrd() {
      this.form2 = {
        password: '',
        confirmPassword: '',
      };
      this.form = {
        loginId: '',
      };
      this.form1 = {
        otpCode: '',
      };

      this.$nextTick(() => {
        this.$validator.reset();
        this.$v.$reset();
      });
    },
    submitFormLoginId: function () {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }
      this.busy = true;

      this.$store
        .dispatch('parametersModule/getParameters')
        .then(() => {
          this.otpSw =
            this.$store.getters['parametersModule/getParameters'].otpSw;
          this.otpMthd =
            this.$store.getters['parametersModule/getParameters'].otpMthd;
          if (this.otpSw == 'Y') {
            this.$store
              .dispatch('authModule/forgotPassword', this.form.loginId)
              .then((data) => {
                this.busy = false;
                if (data.status == 'success') {
                  this.$vToastify.success({
                    body: data.message,
                    defaultTitle: false,
                  });
                  if (this.active++ > 2) this.active = 0;
                }
              })
              .catch((error) => {
                this.busy = false;
                const message = error.message;
                this.$vToastify.error({
                  body: message,
                  defaultTitle: false,
                });
              });
          } else {
            this.active = 2;
          }
        })
        .catch((error) => {
          const message = error.response.data.message;
          this.$vToastify.error({
            body: message,
            defaultTitle: false,
          });
        });
    },
    submitFormOTP: function () {
      this.busy = true;
      this.$v.form1.$touch();
      if (this.$v.form1.$anyError) {
        return;
      }
      this.$store
        .dispatch('authModule/verifyCode', {
          userId: this.form.loginId,
          code: this.form1.otpCode,
        })
        .then((data) => {
          this.busy = false;
          if (data.status == 'success') {
            this.$vToastify.success({
              body: data.message,
              defaultTitle: false,
            });
            if (this.active++ > 2) this.active = 0;
          } else {
            const message = data.message;
            this.$vToastify.error({
              body: message,
              defaultTitle: false,
            });
          }
        })
        .catch((error) => {
          this.busy = false;
          const message = error.message;
          this.$vToastify.error({
            body: message,
            defaultTitle: false,
          });
        });
    },
    submitFormResetPassword: function () {
      this.busy = true;
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }

        this.$store
          .dispatch('authModule/resetPassword', {
            userId: this.form.loginId,
            password: this.form2.password,
            passwordConfirm: this.form2.confirmPassword,
            code: this.form1.otpCode,
          })
          .then((data) => {
            this.busy = false;
            if (data.status == 'success') {
              this.$vToastify.success({
                body: data.message,
                defaultTitle: false,
              });
              if (this.active++ > 2) this.active = 0;
              this.form.userId = '';
              this.form1.otpCode = '';
              this.$v.form.$reset();
              this.$v.form1.$reset();
              this.resetFormResetPassowrd();
              this.hideModal();
            }
          })
          .catch((error) => {
            this.busy = false;
            const message = error.message;
            this.$vToastify.error({
              body: message,
              defaultTitle: false,
            });
          });
      });
    },
  },
};
</script>
<style type="text/css">
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
#random_text {
  height: 41px;
  padding: 4px 5px 0 10px;
  font-size: 28px;
  color: #00bc8b;
  font-family: 'Dancing Script', cursive;
}
#Retrieve_by_email {
  color: #f6a604;
}
#reverification_code {
  width: 185px;
  margin-right: 10px;
}
.modal-dialog {
  max-width: 680px;
}
.otp-btn {
  cursor: pointer;
}
.redhint {
  width: 20px;
}
.modal-backdrop {
  background: transparent;
}
.register-erorr {
  color: red !important;
  line-height: 3;
}
#modal-password .modal-content {
  background: linear-gradient(138deg, #e0f0fb 0%, #fcfcfc 57%, #fefdfc 100%);
  border-radius: 0;
  border: unset;
}
.modal-body {
  padding-top: 0;
}
.btn-login {
  background: #ffb423;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border: 1px solid #ffb423;
  padding: 0.5rem 1.3rem;
  font-size: 1.5rem;
}

.modal-from-tour .nav-tabs {
  border: none;
}
.modal-from-tour
  .nav-tabs
  .nav-item
  .nav-link
  .nav-tabs
  .nav-item
  .nav-link.active {
  color: #191717 !important;
  border-top: unset !important;
  border: unset !important;
}
.modal-from-tour .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #999999;
}
.modal-from-tour .nav-tabs .nav-item .nav-link {
  font-size: 1.5rem;
  color: #999999;
  border: unset;
  position: relative;
  background: unset;
}
.modal-from-tour .nav-tabs .nav-link:hover,
.modal-from-tour .nav-tabs .nav-link:focus {
  border: unset !important;
}
.modal-from-tour .nav-tabs .nav-item .nav-link.active:hover {
  color: #191717;
  border: unset;
}
.modal-from-tour .nav-tabs .nav-item .nav-link.active {
  margin-top: 2px;
  color: #191717;
  border-top: unset;
}
.modal-from-tour .nav-tabs .nav-item:first-child a:after {
  content: '';
  border-right: 2px solid #cccccc;
  position: absolute;
  height: 50%;
  right: 0;
  top: 25%;
}
.modal-form input {
  border-radius: unset;
  border: 1px solid #fff;
}
.card-body {
  padding-top: 0 !important;
  background: unset;
}
.modal-form input,
.otp-btn {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16) !important;
}
.modal-header {
  border-bottom: unset;
}
.forgot-link a,
.sign-up-link {
  color: #999999;
}
.forgot-link a:hover {
  color: #000;
}
.sign-up-link a {
  color: #1997f8;
}
.login-text {
  font-size: 0.9rem;
  color: #999999;
}
.login-text span {
  color: #a5a5a5;
}
.input-group-text.otp-btn {
  background: #ffb423;
  color: #fff;
  border-radius: unset;
}
.modal-form-sign-up span.md-guide {
  color: #a5a5a5;
  padding: 0 0.6rem;
  line-height: 3;
}
.join-member-group label {
  margin-right: 1rem;
}
.join-member-group .custom-control-inline {
  margin-right: 0;
}
.join-member-group {
  color: #333333;
}
.join-member-group input {
  border-radius: 0;
}
.notify-hidden {
  display: none;
}
.phone_prefix {
  border-radius: unset;
}
.custom-select.phone_prefix {
  height: calc(1.56em + 1.06rem + 1px);
  border: 1px solid #fff;
  flex: unset;
  width: 22%;
}
.input-group-text.otp-btn:hover {
  color: #fff;
  background-color: #f4772e;
  border-color: #f4772e;
  box-shadow: 0 0.22rem 0.525rem rgb(244 119 46 / 40%),
    0 0.0625rem 0.385rem rgb(244 119 46 / 54%);
}
</style>
3h532
